<template>
    <div v-if="infoCookies" class="info-cookies">
        <div class="wrapper-info-cookies" v-if=noUnillever>
            <CookieTemplate @setPermissionCookie="setPermissionCookie()"/>
        </div>
    </div>
</template>

<script>
import Vue from 'vue';
import VueCookies from 'vue-cookies';
import CookieTemplate from "./CookieTemplate";

Vue.use(VueCookies);
export default {
    components: {CookieTemplate},
    data() {
        return {
            infoCookies: true,
            noUnillever: true
        }
    },
    methods: {
        checkPermissionCookies: function () {
            if (this.$cookies.isKey("3zc1ntcv")) {
                this.infoCookies = false;
            } else {
                this.infoCookies = true;
            }
        },
        setPermissionCookie: function () {
            this.$cookies.config("30d");
            this.$cookies.set("3zc1ntcv", "templatevue");
            this.infoCookies = false;
        },
    },
    created() {
        //check if user accept cookie
        this.checkPermissionCookies();
    },
}
</script>

<style lang="scss" scoped>
@import "../assets/scss/global";

.info-cookies {
    position: fixed;
    bottom: 0;
    z-index: 9;
    width: 100%;
    box-shadow: 0 0 1em #00000021;
    background-color: $white;

    .wrapper-info-cookies {
        background-color: $white;
        padding: 10px;
        max-width: 1200px;
        margin: 0 auto;

        img {
            max-width: 80px;
        }

        .text {
            margin-bottom: 5px;
            line-height: normal;
        }

        p, a {
            margin-bottom: 10px;
            color: $black;
            font-family: Arial, Helvetica, sans-serif;
            font-size: 14px;
        }

        a {
            text-decoration: underline;
            color: inherit;
            font-weight: 700;
        }

        &.cookies-unilever {
            max-width: none;
            border-top: 1px solid #1F36C7;

            p, a {
                margin-bottom: 0;
            }

            a {
                color: #1276ce !important;
            }
        }
    }
}
</style>
