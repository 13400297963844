<template>
    <div>
        <div>
            <b-row class="d-flex">
                <b-col class="d-flex align-items-center justify-content-center flex-column">
                    <p>
                        <strong>Este website utiliza cookies</strong>
                    </p>
                    <p class="text-center">
                        As configurações de cookies neste site são definidas para que possamos dar-lhe a melhor experiência
                        enquanto estiver aqui.
                        Se desejar, você pode alterar as configurações de cookies a qualquer momento em seu navegador.
                        <span
                            style="text-decoration: underline; cursor: pointer"
                            @click="openModaCookies = true"
                        >Saiba mais</span>.
                    </p>
                </b-col>
            </b-row>

            <b-row>
                <b-col cols="12" class="d-flex align-items-center justify-content-center ms-2 ms-lg-0">
                    <b-button @click="setPermissionCookie()" class="btn-prosseguir mb-3">
                        Prosseguir
                    </b-button>
                </b-col>
            </b-row>
        </div>
        <Modal
            v-if="openModaCookies"
            :name="'Modal Cookies'"
            :openModal="openModaCookies"
            :size="'lg'"
            :classCustom="'modal-cookies'"
        >
            <b-row>
                <b-col class="d-flex align-items-center justify-content-end">
                    <div
                        class="wrapper-icon-modal d-flex align-items-center justify-content-center"
                    >
                        <b-icon
                            @click="openModaCookies = false"
                            icon="x"
                        ></b-icon>
                    </div>
                </b-col>
            </b-row>

            <b-row>
                <b-col class="d-flex justify-content-center align-items-center flex-column mt-3">
                    <h1>Termos De Uso De Cookies</h1>
                    <br>
                    <div class="center">

                        <p>
                            <strong>O que são cookies?</strong>
                        </p>
                        <p>
                            Cookies são arquivos-texto gerados pelo site e transferidos para seu
                            computador sendo armazenados pelos programas de navegação (“Browsers”). Em
                            geral, os Cookies são utilizados para uma otimização de sua experiência de
                            navegação quando permitido. Sempre que o seu navegador estiver assim
                            configurado, utilizaremos este recurso.
                        </p>
                        <p>
                            Os cookies utilizados neste site têm como finalidade identificar as
                            tendências de navegação do usuário, buscando aprimorar e personalizar seus
                            acessos futuros, tais como, páginas navegadas ou links clicados. Quaisquer
                            dos cookies utilizados não são usados para executar programas em seu
                            dispositivo, tampouco possibilitam a infecção de seu dispositivo com vírus
                            ou programas maliciosos.
                        </p>
                        <p>
                            Mesmo quando seu programa de navegação estiver configurado para permitir a
                            instalação de cookies, você poderá impedir este recurso em seu navegador e
                            excluir todos os eventuais cookies já implementados.
                        </p>
                        <p>
                            Desta forma, recomendamos que você configure seu programa de navegação no
                            nível de segurança desejado antes de navegar em nosso site em todos os
                            computadores e dispositivos que deseje utilizar.
                        </p>
                        <p>
                            Os cookies utilizados neste site atendem aos requisitos legais e podem
                            estar enquadrados nas seguintes categorias:
                        </p>
                        <ul>
                            <li>
                                COOKIES ESTRITAMENTE NECESSÁRIOS
                            </li>
                            <li>
                                COOKIES DE DESEMPENHO
                            </li>
                            <li>
                                COOKIES DE FUNCIONALIDADE
                            </li>
                            <li>
                                COOKIES DE SEGMENTAÇÃO
                            </li>
                        </ul>
                        <br>
                        <p>
                            Cookies Estritamente Necessários: São aqueles cookies que permitem a você
                            navegar pelo site e usar recursos essenciais, como áreas seguras, por
                            exemplo. Esses cookies não guardam quaisquer informações sobre você que
                            possam ser usadas em ações de comunicação de produto ou serviço ou para
                            lembrar as páginas navegadas no site.
                        </p>
                        <br>
                        <p>
                            Para o que nós os usamos:
                        </p>
                        <ul type="disc">
                            <li>
                                Fornecer estatísticas sobre como nosso site é utilizado.
                            </li>
                            <li>
                                Ajudar na melhoria de nosso site pela medição de erros que
                                eventualmente possam ocorrer.
                            </li>
                            <li>
                                Realizar melhorias e aperfeiçoamentos em nosso site decorrentes do
                                comportamento de navegação de nossos usuários.
                            </li>
                        </ul>
                        <br>
                        <p>
                            COOKIES DE SEGMENTAÇÃO: Os cookies de "Segmentação" estão ligados aos
                            serviços prestados por terceiros, tais como, por exemplo, botões de "Like"
                            e botões de "Compartilhar" contidos em outros sites que não neste site. O
                            terceiro fornece esses serviços por reconhecer que você visitou nosso site.
                        </p>
                        <br>
                        <p>
                            Para o que nós os usamos:
                        </p>
                        <ul type="disc">
                            <li>
                                Para conectar-se a redes sociais como o Facebook, que, por serem
                                terceiros e pelos quais não termos controle, poderão, posteriormente,
                                utilizar as informações sobre a sua visita para direcionar publicidade
                                de seu interesse para você em outros sites.
                            </li>
                            <li>
                                Para fornecermos às agências de publicidade informações sobre a sua
                                visita e para que estas possam analisar a origem de seus visitantes e
                                gerar estatísticas.
                            </li>
                        </ul>
                        <br>
                        <p>
                            <strong>Como desativar os cookies</strong>
                        </p>
                        <p>
                            Normalmente as configurações para desativar os cookies estão presentes nas
                            "opções" ou o menu "Preferências" do seu navegador. Para saber melhor
                            consulte as opções de ajuda de seu navegador. Abaixo você encontrará alguns
                            links para os navegadores mais conhecidos:
                        </p>
                        <br>
                        <ul>
                            <li><a target="_blank" href="https://support.microsoft.com/en-us/help/17479/windows-internet-explorer-11-change-security-privacy-settings#ie=ie-11">Internet Explorer</a></li>
                            <li><a target="_blank" href="https://support.mozilla.org/pt-BR/kb/cookies-informacoes-sites-armazenam-no-computador?redirectslug=cookies-informacoes-armazenadas-por-sites-em-seu-c&redirectlocale=pt-BR">Firefox</a></li>
                            <li><a target="_blank" href="https://support.google.com/chrome/answer/95647?ref_topic=3434352&hl=pt-BR">Chrome</a></li>
                            <li><a target="_blank" href="https://support.apple.com/pt-br/HT201265">Safari Web e iOS</a></li>
                        </ul>
                    </div>
                </b-col>
            </b-row>
        </Modal>
    </div>
</template>

<script>
export default {
    name: "CookieTemplate",
    components: {
        Modal: () => import('../components/Modal'),
    },
    data() {
        return{
            openModaCookies: false,
        }
    },
    methods: {
        setPermissionCookie() {
            this.$emit('setPermissionCookie');
        }
    }
}
</script>

<style  lang="scss" scoped>
@import "../assets/scss/global";
.info-cookies {
    position: fixed;
    bottom: 0;
    z-index: 9;
    width: 100%;
    box-shadow: 0 0 1em #00000021;
    background-color: $white;

    .wrapper-info-cookies {
        background-color: $white;
        padding: 10px;
        max-width: 1200px;
        margin: 0 auto;

        img {
            max-width: 80px;
        }

        .text {
            margin-bottom: 5px;
            line-height: normal;
        }

        p, a, li {
            margin-bottom: 10px;
            color: $black;
            font-family: Arial, Helvetica, sans-serif;
            font-size: 14px;
        }

        a {
            text-decoration: underline;
            color: inherit;
            font-weight: 700;
        }

        &.cookies-unilever {
            max-width: none;
            border-top: 1px solid #1F36C7;

            p, a {
                margin-bottom: 0;
            }

            a {
                color: #1276ce !important;
            }
        }
        .btn-prosseguir {
            background: #f9c827;
            color: black;
        }
    }
}

</style>
